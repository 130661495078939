/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, FormEvent, useState } from "react";
import { useTheme } from "@/wireframes/theme/theme-provider";

import { useTranslation } from "react-i18next";
import ReCAPTCHAComponent from "@/components/header/ReCAPTCHAComponent";
import { Input } from "@/components/ui/input";
import ShimmerButton from "@/wireframes/buttons/shimmer-button";
import { Textarea } from "@/components/ui/textarea";
import Globe from "@/wireframes/components/globe";
import HeroSectionChildren from "@/wireframes/page-sections/hero/HeroSectionChildren";
import GradualSpacing from "@/wireframes/text-animations/gradual-spacing";

const isNameValid = (fullName) => {
  if (!fullName) return { isValid: false, message: "Full name is required" };
  return { isValid: true, message: "" };
};

const isEmailValid = (email) => {
  const emailRegex = /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/;
  if (!email) return { isValid: false, message: "Email is required" };
  if (!emailRegex.test(email))
    return { isValid: false, message: "Email is not valid" };
  return { isValid: true, message: "" };
};

const isContactValid = (contact) => {
  if (!contact) return { isValid: false, message: "Contact is required" };
  return { isValid: true, message: "" };
};

const isDescriptionValid = (message) => {
  if (!message) return { isValid: false, message: "Message is required" };
  return { isValid: true, message: "" };
};
const ContactUsForm: React.FC = () => {
  const { theme } = useTheme();

  const themeBgClass = theme === "dark" ? "bg-neutral-100" : "bg-black";
  const themeBgBtn = theme === "dark" ? "bg-black" : "bg-neutral-100";
  const themeTextClass = theme === "dark" ? "text-black" : "text-white";

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contact: "",
    message: "",
  });

  const [validationData, setValidationData] = useState({
    fullNameValidation: { isValid: true, message: "" },
    emailValidation: { isValid: true, message: "" },
    contactValidation: { isValid: true, message: "" },
    messageValidation: { isValid: true, message: "" },
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate form data
    const fullNameValidation = isNameValid(formData.fullName);
    const emailValidation = isEmailValid(formData.email);
    const contactValidation = isContactValid(formData.contact);
    const messageValidation = isDescriptionValid(formData.message);

    setValidationData({
      fullNameValidation,
      emailValidation,
      contactValidation,
      messageValidation,
    });

    // Check if all fields are valid
    if (
      fullNameValidation.isValid &&
      emailValidation.isValid &&
      contactValidation.isValid &&
      messageValidation.isValid
    ) {
      console.log("User data", formData);

      // Reset form data
      setFormData({
        fullName: "",
        email: "",
        contact: "",
        message: "",
      });

      // Optionally, redirect or perform other actions
      window.location.href = "";
    }
  };

  return (
    <HeroSectionChildren id="contact-us">
      <div className="container mx-auto mb-12 flex flex-col justify-center items-start">
        <header className="text-start">
          {/* <h5 className="text-3xl sm:text-3xl md:text-3xl lg:text-5xl leading-normal font-normal underline-offset-8 mt-10 md:mt-8">
            Get In Touch
          </h5> */}
          <GradualSpacing
            className="text-3xl sm:text-3xl md:text-3xl lg:text-5xl text-wrap text-start leading-normal font-bold mt-20 sm:mt-12 md:mt-8 lg:mt-10"
            text="Get In Touch"
          />
        </header>
        <div className="text-start mb-4">
          <h5 className="text-3xl sm:text-3xl md:text-3xl lg:text-5xl leading-normal font-bolder py-3">
            Say something, we'd <span className="text-primary">love to</span>{" "}
            chat.
          </h5>
        </div>
        <div className="text-start mb-8">
          <h5 className="text-md sm:text-md md:text-lg lg:text-xl font-normal mb-4">
            If you want to inquire more about our service please use the contact
            form.
          </h5>
        </div>

        <div className="w-full flex justify-center">
          <div className="w-full max-w-3xxl flex flex-wrap">
            <div className="w-full">
              <form
                className={`rounded pt-6 mb-4 p-4 `}
                method="post"
                onSubmit={handleSubmit}
              >
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-2">
                  <div className="mb-4">
                    <label
                      className={`block text-start text-sm font-bold mb-2`}
                      htmlFor="fullName"
                    >
                      Full Name
                    </label>
                    <Input
                      className={`shadow appearance-none border rounded w-full py-2 text-gray-700 bg-transparent leading-tight focus:outline-none focus:shadow-outline text-accent`}
                      id="full_name"
                      name="fullName"
                      type="text"
                      placeholder="Full name"
                      value={formData.fullName}
                      onChange={handleChange}
                    />
                    {!validationData.fullNameValidation.isValid && (
                      <p className="text-primary text-start text-sm">
                        {validationData.fullNameValidation.message}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label
                      className={`block text-start text-sm font-bold mb-2`}
                      htmlFor="contact"
                    >
                      Contact
                    </label>
                    <Input
                      className={`shadow appearance-none  border rounded w-full py-2 bg-transparent leading-tight focus:outline-none focus:shadow-outline text-accent`}
                      id="contact"
                      name="contact"
                      type="text"
                      placeholder="Contact"
                      value={formData.contact}
                      onChange={handleChange}
                    />
                    {!validationData.contactValidation.isValid && (
                      <p className="text-primary text-start text-sm">
                        {validationData.contactValidation.message}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label
                      className={`block text-start text-sm font-bold mb-2`}
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <Input
                      className={`shadow appearance-none border rounded w-full py-2 px-3 bg-transparent leading-tight focus:outline-none focus:shadow-outline text-accent`}
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {!validationData.emailValidation.isValid && (
                      <p className="text-primary text-start text-sm">
                        {validationData.emailValidation.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="mb-4">
                  <label
                    className={`block text-start text-sm font-bold mb-2`}
                    htmlFor="message"
                  >
                    Message
                  </label>
                  <Textarea
                    id="message"
                    name="message"
                    rows={4}
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Message"
                    className="rounded"
                  />
                  {!validationData.messageValidation.isValid && (
                    <p className="text-primary text-start text-sm">
                      {validationData.messageValidation.message}
                    </p>
                  )}
                </div>
                {/* <div className="flex justify-center mb-4 mt-6 mb-6">
                  <ReCAPTCHAComponent />
                </div> */}
                <div className={`z-10 flex items-center justify-center`}>
                  <ShimmerButton
                    shimmerColor="hsl(var(--text-primary-foreground))"
                    background="hsl(var(--primary))"
                    className={`shadow-2xl border border-black`}
                  >
                    <span
                      className={`whitespace-pre-wrap text-center text-sm font-medium leading-none  0 lg:text-lg`}
                    >
                      SUBMIT
                    </span>
                  </ShimmerButton>
                </div>
              </form>
            </div>

            {/* <div className="hidden md:flex w-full h-full md:w-1/2 items-center justify-center">
              <div className="relative flex h-full w-full max-w-[32rem] items-center justify-center overflow-hidden rounded-lg p-8 md:px-40 md:pb-60 ">
                <Globe className="absolute top-1/2 transform -translate-y-1/2" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </HeroSectionChildren>
  );
};

export default ContactUsForm;
