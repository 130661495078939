import React from "react";
import GradualSpacing from "@/wireframes/text-animations/gradual-spacing";
import Marquee from "@/wireframes/components/marquee";

interface MarqueeItem {
  id: string;
  name: string;
  imgLight: string;
  imgDark: string;
}

interface HeroSectionProps {
  id?: string;
  title?: string;
  subTitle?: string;
  description?: string;
  marqueeList: MarqueeItem[];
  PatternComponent: React.FC | null;
  MarqueeCardComponent: React.FC<{ imgLight: string; imgDark: string }>;
}

const HeroSectionWithProps: React.FC<HeroSectionProps> = ({
  id,
  title,
  subTitle,
  description,
  marqueeList = [],
  PatternComponent = null,
  MarqueeCardComponent,
}) => {

  return (
    <section
      id={id}
      className={`flex flex-col justify-center items-center min-h-screen px-4`}
    >
      {PatternComponent != null && <PatternComponent />}
      <div className={`container mx-auto px-4 py-4 mb-10`}>
        <header className={`text-start mb-8 px-4 sm:my-10 `}>
          <GradualSpacing
            className="text-3xl sm:text-3xl md:text-3xl lg:text-5xl text-wrap text-start leading-normal font-bold mt-20 sm:mt-12 md:mt-8 lg:mt-10"
            text={title}
          />
        </header>
        <div className={`text-start mb-8 px-4`}>
          <h5 className="text-2xl font-normal mb-4">{subTitle}</h5>
        </div>
        <section className="mb-10 px-4">
          <p className={`text-lg text-start max-w-3xl mt-10 sm:text-md`}>
            {description}
          </p>
        </section>
        {marqueeList.length != 0 && (
          <div className="w-full px-4 ">
            <div className={`flex flex-col justify-center items-center`}>
              <div className="w-full">
                <Marquee
                  pauseOnHover
                  className="[--duration:40s] flex items-center"
                >
                  {marqueeList.map((marquee) => (
                    <MarqueeCardComponent
                      key={marquee.id}
                      imgLight={marquee.imgLight}
                      imgDark={marquee.imgDark}
                    />
                  ))}
                </Marquee>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default HeroSectionWithProps;
