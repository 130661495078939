/* eslint-disable @typescript-eslint/no-unused-vars */
// src/pages/HomePage.tsx
import React, { useEffect } from "react";
import HeroSectionChildren from "@/wireframes/page-sections/hero/HeroSectionChildren";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

const BlankPage: React.FC = () => {
  return (
    <HeroSectionChildren id="home">
      <div className={`container mx-auto px-4 py-8`}>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="flex flex-col items-center justify-center p-4 mt-4">
            <div>
              <h1>Heading 1</h1>
              <h2>Heading 2</h2>
              <h3>Heading 3</h3>
              <h4>Heading 4</h4>
              <h5>Heading 5</h5>
              <h6>Heading 6</h6>
              <p>Subtitle</p>
              <p>Title</p>
              <p>Body text</p>
              <p>Paragraph text</p>
              <h2>Card Title</h2>
              <p>Card Body</p>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center p-4">
            <Card>
              <CardHeader>
                <CardTitle>Card Title</CardTitle>
                <CardDescription>
                  <p>Card Description</p>
                </CardDescription>
              </CardHeader>
              <CardContent>
                <p>Card Content</p>
              </CardContent>
              <CardFooter>
                <p>Card Footer</p>
              </CardFooter>
            </Card>
            <Button variant="outline" className="mt-4">Button</Button>
          </div>

          <div className="flex flex-col items-center justify-center p-4">
            <Card>
              <CardHeader>
                <CardTitle>Card Title</CardTitle>
                <CardDescription>
                  <p>Card Description</p>
                </CardDescription>
              </CardHeader>
              <CardContent>
                <p>Card Content</p>
              </CardContent>
              <CardFooter>
                <p>Card Footer</p>
              </CardFooter>
            </Card>
            <Button variant="outline" className={`mt-4`}>Button</Button>
          </div>

          <div className="flex flex-col items-center justify-center p-4">
            <Card>
              <CardHeader>
                <CardTitle>Card Title</CardTitle>
                <CardDescription>
                  <p>Card Description</p>
                </CardDescription>
              </CardHeader>
              <CardContent>
                <p>Card Content</p>
              </CardContent>
              <CardFooter>
                <p>Card Footer</p>
              </CardFooter>
            </Card>
            <Button variant="outline" className={` mt-4`}>Button</Button>
          </div>
        </div>
      </div>
    </HeroSectionChildren>
  );
};

export default BlankPage;
