/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

const HeroSectionChildren: React.FC<{ id: string; children: any }> = ({
  id,
  children,
}) => {
  return (
    <section
      id={id}
      className={`flex flex-col justify-center items-center min-h-screen px-4 md:px-8 lg:px-16 `}
    >
      <div className={`text-center w-full px-4 md:px-8`}>{children}</div>
    </section>
  );
};

export default HeroSectionChildren;
